<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="page-user-list">
    <!--<GroupSidebarForm :isSidebarActive="addNewDataSidebar"  @closeSidebar="toggleDataSidebar" :data="sidebarData"/>-->
    <EylfSetForm :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"
                 @reFetchData="reFetchData"
    />

    <vx-card ref="filterCard" class="user-list-filters mb-8">
      <div class="flex align-items-center mb-8">
        <h2 class="mb-0">Early Learning Framework Outcomes</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
        </div>
        <div class="vx-col md:w-1/2 w-full flex justify-end">
          <vs-button color="primary" type="filled" @click="addNewRegion">Add Early Learning Framework</vs-button>
        </div>
      </div>
      <br>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          <label class="block mb-2">Country</label>
          <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter" class="mb-4 md:mb-0"/>
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          <label class="block mb-2">Region</label>
          <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter" class="mb-4 md:mb-0"/>
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
          <label class="block mb-2">Sort By</label>
          <v-select :options="sortByOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="sortByFilter"/>
        </div>
      </div>
    </vx-card>

    <vx-card>
      <vs-table max-items="20" :pagination="elyfSets.length > 0" :data="elyfSets">

        <template slot="thead">
          <vs-th sort-key="country">Country</vs-th>
          <vs-th sort-key="state">Region</vs-th>
          <vs-th>Early Learning Framework</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.country[0]">
              {{ tr.country[0] }}
            </vs-td>
            <vs-td :data="tr.state">
              {{ tr.state }}
            </vs-td>
            <vs-td :data="tr.title">
              {{ tr.title }}
            </vs-td>

            <vs-td :data="tr._id">
              <a href="#" @click.stop="viewEylfList(tr._id)">
                <feather-icon icon="EyeIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
              <a href="#" @click.stop="deleteSet(tr._id)">
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current"
                              class="ml-2"/>
              </a>

            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vx-card>

  </div>

</template>

<script>
import vSelect from 'vue-select'
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import EylfSetForm from "./EylfSetForm";
import {mapActions} from "vuex";

export default {
  components: {
    vSelect,
    EylfSetForm
  },

  data() {
    return {
      groups: {},
      countryFilter: {label: 'All', value: 'all', states: [{label: 'All', value: 'all'}]},
      countryOptions: [],
      stateFilter: {label: 'All', value: 'all'},
      stateOptions: [],
      sortByFilter: {label: 'Country', value: ''},
      sortByOptions: [
        {label: 'Name', value: 'title'},
        {label: 'Country', value: 'country.name'},
        {label: 'Region', value: 'states.name'},
      ],

      searchQuery: "",
      awaitingSearch: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      elyfSets: [],

      itemPerPage: 10,
      page: 1,
      totalPages: 1,

    }
  },
  watch: {
    countryFilter(obj) {
      if (obj.value == "all") {
        this.stateOptions = [];
        this.stateFilter = {label: 'All', value: 'all'},
          this.getEylfSets();
      } else {
        this.stateOptions = obj.states;
        this.stateFilter = this.stateOptions[0];
        this.getEylfSets();
      }

    },
    stateFilter(obj) {
      this.getEylfSets();
    },
    sortByFilter(obj) {
      this.getEylfSets();
    },
    searchQuery: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getEylfSets();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  },
  methods: {
    ...mapActions("earlyYearLearningFramework", [
      "getAllEylfSet",
      "deleteEYLFSetById"
    ]),

    ...mapActions("country", [
      "getAllStates",
      "getAllCountries",
    ]),
    reFetchData() {
      this.countryFilter = {label: 'All', value: 'all'};
      this.getAllCountries()
        .then((res) => {
          this.countryOptions = this.$store.state.country.countryOptions;
          // console.log(this.countryOptions, "testing");
          this.getEylfSets();
        })
        .catch(err => {
          console.error(err)
        })
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    handleChangePage(pageNum) {
      this.page = pageNum;
      this.getEylfSets();
    },
    viewEylfList(stateId) {
      this.$router.push({path: '/admin/learning-center-presets/early-years-learning-framework/' + stateId});

    },
    deleteSet(setId) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete(setId)
        }
      })

    },
    async delete(setId) {

      this.$vs.loading();

      this.deleteEYLFSetById(setId)
        .then((res) => {
          this.$vs.loading.close()
          this.showMessage("Success", "EYLF Set deleted successfully.", "success");
          this.getEylfSets();
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage("Failure", "EYLF Set can not be deleted.", "danger");
        });
    },
    getEylfSets() {
      const filterQuery = {
        country: this.countryFilter.value,
        state: this.stateFilter.value,
        sort: this.sortByFilter.value,
        search: this.searchQuery,
        page: this.page,
        limit: this.itemPerPage,
        dir: "asc"
      }

      this.$vs.loading()
      this.getAllEylfSet(filterQuery)
        .then((res) => {
          this.$vs.loading.close()
          this.elyfSets = res.data.data.docs;
          let totalDoc = res.data.data.pagination;

          this.totalPages = (totalDoc / this.itemPerPage) >
          parseInt(totalDoc / this.itemPerPage)
            ? parseInt(totalDoc / this.itemPerPage) + 1
            : parseInt(totalDoc / this.itemPerPage);
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    addNewRegion() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },

    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
  created() {

    this.$vs.loading()
    this.getAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.country.countryOptions;
        this.getEylfSets();
      })
      .catch(err => {
        console.error(err)
      })
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
