<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h2>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Early Learning Framework</h2>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <!--        <VuePerfectScrollbar class="scroll-area&#45;&#45;data-list-add-new" :settings="settings" :key="$vs.rtl">-->

    <div class="p-6">

      <label class="block mt-5">Select Country</label>
      <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="countryFilter" class="mb-4 md:mb-0"/>
      <span class="text-danger text-sm">{{ errors.first("Country") }}</span>

      <label class="block mt-5">Select State</label>
      <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
      @input="onStateChange" v-model="stateFilter" class="mb-4 md:mb-0"/>
      <span class="text-danger text-sm">{{ errors.first("State") }}</span>

      <label class="block mt-5">Add Eylf</label>
      <vs-input class="sm:mr-0 sm:w-auto w-full" v-model="eylfTitle"/>
      <span class="text-danger text-sm">{{ errors.first("eylf") }}</span>

    </div>

    <!--        </VuePerfectScrollbar>-->

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

import {mapActions} from "vuex";

export default {
  data() {
    return {
      countryOptions: [],
      stateOptions: [],
      countryFilter: {label: "All", value: "all"},
      stateFilter: {label: "All", value: "all"},
      eylfTitle: "",
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },

  watch: {
    countryFilter(obj) {

      if (obj.value == "all") {
        this.stateOptions = [];
        this.stateFilter = {label: 'All', value: 'all'}
      } else {
        this.stateOptions = obj.states;
        this.stateFilter = this.stateOptions[0];
      }
    },

    isSidebarActive(val) {

      this.countryOptions = this.$store.state.country.countryOptions;
      this.errors.clear()
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.dataId = this.data._id
        this.eylfTitle = this.data.title
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },

  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return this.countryFilter.value != "all" && this.stateFilter.value != "all" && this.eylfTitle;
    }
  },
  methods: {
    ...mapActions("earlyYearLearningFramework", [
      "addEylfSet"
    ]),
    onStateChange() {
      this.errors.clear();
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    initValues() {
      if (this.data._id) return
      this.countryFilter = {label: 'All', value: 'all'};
      this.eylfTitle = "";
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (this.stateFilter.value == "") {
          this.errors.add({
                field: "State",
                msg: "State cant be 'All'"
          });
          return;
        }

        if (result) {
          const data = {
            country: this.countryFilter.value,
            state: this.stateFilter.value,
            stateID: this.stateFilter._id,
            title: this.eylfTitle,
          }
          this.$vs.loading()
          this.addEylfSet(data)
          .then((res) => {
              this.errors.clear();
              this.$vs.loading.close()
              this.showMessage("Success", "Data added successfully.", "success");
              this.$emit("reFetchData");
              this.$emit('closeSidebar')
              this.initValues()
            })
            .catch(err => {
              this.$vs.loading.close()
              if (err.response.status === 422) {
                this.errors.add({
                  field: "State",
                  msg: err.response.data.message
                });
              } else {
                this.$vs.notify({
                  title: 'Failure',
                  text: "Create Failed",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }
              console.error(err)
            })
        }
      })
    },
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
